<template>
  <b-card :title="$t('Trial Balance')">
    <b-overlay
      :show="showOverLay"
      rounded="sm"
      :opacity="0.5"
    >
      <b-row>
        <b-col md="12">
          <!-- Trial Balance TABLE -->
          <b-card
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="BookOpenIcon"
                  size="18"
                />
                <span class="align-middle ml-50">{{ `${$t('Trial Balance')} ${$t('General.Report')}` }}</span>
              </b-card-title>
            </b-card-header>
            <b-table
              striped
              responsive
              class="mb-0"
              :items="report_data"
            >
              <template #cell(date)="data">
                {{ new Date(data.value).toDateString() }}
              </template>
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col cols="6">
          <!-- alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="showError"
            class="text-left p-1"
          >
            <ul class="list-style-icons mb-0">
              <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
            </ul>
          </b-alert>
        </b-col>
        <b-col cols="3" />
        <b-col cols="3" />
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BOverlay, BAlert, BTable, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import report from '@/service/accounting/report'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      report_data: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
    }
  },
  async created() {
    this.getReportApi()
  },
  methods: {
    async getReportApi() {
      this.showOverLay = true
      this.report_data = []
      await report.trialBalance().then(response => {
        this.report_data = response.data
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.ReportSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.ReportError'), 'danger')
        return error
      })
      this.showOverLay = false
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}
</script>
